
import { defineComponent } from 'vue';
import BaseTable from '@/components/ui/BaseTable.vue';
import {
  Certificate,
  CertificateProductGroup,
  CertificateRequestsStatus,
  Request
} from '@/models/CertificateModel';
import UploadAndAddRequestModal from '@/components/modals/UploadAndAddRequestModal.vue';
import BasePagination from '@/components/ui/BasePagination.vue';
import SelectElementWithContentModal from '@/components/modals/SelectElementWithContentModal.vue';
import BaseCheckbox from '@/components/ui/BaseCheckbox.vue';
import ApproveChooseRequestsModal from '@/components/modals/ApproveChooseRequestsModal.vue';
import CertificateFiltersModal
  from '@/components/modals/CertificateFiltersModal.vue';
import { MessageModalMutationTypes } from '@/store/modules/message-modal/mutation-types';
import BaseSelect from '@/components/ui/BaseSelect.vue';

export default defineComponent({
  name: 'CertificatesRequest',
  components: {
    CertificateFiltersModal,
    ApproveChooseRequestsModal,
    SelectElementWithContentModal,
    UploadAndAddRequestModal,
    BaseTable,
    BasePagination,
    BaseCheckbox,
    BaseSelect
  },
  data: () => ({
    activeFilters: null,
    documentSettings: {
      withApproved: false,
      isPending: true,
    },
    listCheckedRequests: [] as Request[],
    pageable: {
      pageNumber: 0,
      pageSize: 50,
      totalPages: null,
      filters: {
        status: null as null | CertificateRequestsStatus
      }
    }
  }),
  computed: {
    firstSelectedRequest(): Request | undefined {
      if (this.listCheckedRequests.length) {
        return this.listCheckedRequests && this.listCheckedRequests[0]
      }
    },
    isDisabledCheckbox(): any {
      return (item: Request) => {
        if (this.firstSelectedRequest?.requestId) {
          return this.firstSelectedRequest.requestId !== item.requestId
        }

        return this.listCheckedRequests.length &&
          this.listCheckedRequests[0].status !== item.status
      }
    },
    nextRequestsStatus(): string | undefined {
      if (this.listCheckedRequests.length) {
        const firstRequest = this.listCheckedRequests && this.listCheckedRequests[0] as Request
        const certificateStatuses: { [x: string]: any } = CertificateRequestsStatus

        let nextStatus = CertificateRequestsStatus.REQUESTED

        switch (certificateStatuses[firstRequest.status]) {
          case CertificateRequestsStatus.REQUESTED:
            nextStatus = CertificateRequestsStatus.PENDING
            break;
          case CertificateRequestsStatus.PENDING:
            nextStatus = CertificateRequestsStatus.SUCCESS
        }

        return nextStatus
      }
    },
    statusText(): string {
      return `Изменить статус выбранных заявок на статус "${this.nextRequestsStatus}"`
    },
    isCheckedRequest() {
      return (request: Request) => this.listCheckedRequests.findIndex((checkedRequest: Request) => {
        return request.id === checkedRequest.id
      }) >= 0
    },
    requests(): any {
      return this.$store.getters.getRequests
    },
    headers(): any {
      return [
        {
          title: 'Номер регламента',
          content: (item: Request) => item.numberOfRegulation
        },
        {
          title: 'Артикул',
          content: (item: Request) => item.article
        },
        {
          title: 'Бренд',
          content: (item: Request) => item.brand
        },
        {
          title: 'Товарная группа',
          content: (item: Request) => item.pg && item.pg.length > 50 ? `${item.pg.slice(0, 50).trim()}...` : item.pg
        },
        {
          title: 'Подгруппа',
          content: (item: Request) => item.psg && item.psg.length > 50 ? `${item.psg.slice(0, 50).trim()}...` : item.psg
        },
        {
          title: 'Статус',
          content: (item: Request): any => {
            //TODO
            //@ts-ignore
            if (CertificateRequestsStatus[item.status] === CertificateRequestsStatus.PENDING) {
              //@ts-ignore
              return `${CertificateRequestsStatus[item.status]} (В журнале заявок)`
            }
            //@ts-ignore
            return CertificateRequestsStatus[item.status]
          }
        }
      ]
    }
  },
  methods: {
    openCertificateFiltersModal(isApproved: boolean) {
      const element = document.getElementById('modal-certificate-filters-modal')!
      element.style.display = 'flex'
    },
    closeCertificateFiltersModal() {
      const element = document.getElementById('modal-certificate-filters-modal')!
      element.style.display = 'none'
    },
    changeFilter(event: { value: string | null, type: CertificateRequestsStatus | null}) {
      this.pageable.filters.status = event?.type
        ? event.type
        : null

      this.getRequests({
        ...this.pageable,
        pageNumber: 0
      })
    },
    changeStatus() {
      // if (this.listCheckedRequests.length) {
      //   if (this.nextRequestsStatus === CertificateRequestsStatus.PENDING) {
      //     this.$store.dispatch('changeRequestsStatus', {
      //       ids: this.listCheckedRequests.map((request: Request) => request.id),
      //       isPending: true,
      //       withApproved: false,
      //     }).then(() => {
      //       this.getRequests({ pageNumber: 0})
      //       this.setDefaultSettingsDocument()
      //     })
      //   }
      //
      //   if (this.nextRequestsStatus === CertificateRequestsStatus.SUCCESS) {
      //     this.openModalChooseApproveDate()
      //   }
      // } else {
      //   this.openModalSetFiltersBeforeChangeStatusRequest()
      // }
    },
    async createRequestByArticleIds() {
      this.$store.dispatch('changeRequestsStatus', {
        ids: this.listCheckedRequests.map((request: Request) => request.id),
        isPending: true,
        withApproved: false,
      }).then(() => {
        this.getRequests({ pageNumber: 0})
        this.setDefaultSettingsDocument()
      })
    },
    async changeRequestsStatusWithFilters(
      infoFilters: {
        brands: Array<any>,
        productGroups: Array<any>,
        productSubGroups: Array<any>,
        certificatedProductGroups: Array<any>,
        certificatedProductSubGroups: Array<any>,
        isApproved: boolean
      })
    {
      let subgroups: Array<any> = []

      if (infoFilters.productSubGroups.length) {
        subgroups = infoFilters.productSubGroups
      } else if (infoFilters.productGroups.length) {
        infoFilters.productGroups.map((pg: any) => {
          subgroups = [...subgroups, ...pg.subgroups]
        })
      }

      if (infoFilters.certificatedProductSubGroups.length) {
        subgroups = [...subgroups, infoFilters.certificatedProductSubGroups]
      } else if (infoFilters.certificatedProductGroups.length) {
        infoFilters.certificatedProductGroups.map((certificatedPg: any) => {
          subgroups = [...subgroups, ...certificatedPg.subgroups]
        })
      }

      this.$store.dispatch('changeRequestsStatus', {
        brands: infoFilters.brands.map((brand) => brand.brand),
        psgIds: subgroups.map((subgroup: any) => subgroup.id),
        ids: this.listCheckedRequests.map((request: Request) => request.id),
        isPending: true,
        withApproved: !!infoFilters.isApproved,
      }).then(() => {
        this.getRequests({ pageNumber: 0})
        this.setDefaultSettingsDocument()
        this.closeCertificateFiltersModal()
        this.$store.commit(MessageModalMutationTypes.OPEN_MESSAGE_MODAL, 'SUCCESS')
      }).catch(() => {
        this.$store.commit(MessageModalMutationTypes.OPEN_MESSAGE_MODAL, 'ERROR')
      })
    },
    // closeModalSetFiltersBeforeChangeStatusRequest() {
    //   const element = document.getElementById('modal-set-filters-before-change-status-request')!
    //   element.style.display = 'none'
    // },
    // openModalSetFiltersBeforeChangeStatusRequest() {
    //   const element = document.getElementById('modal-set-filters-before-change-status-request')!
    //   element.style.display = 'flex'
    // },
    // openModalChooseStatus(withApproved: boolean) {
    //   this.documentSettings.withApproved = withApproved
    //
    //   this.closeChooseApproveModal()
    //   const element = document.getElementById('modal-select-element-with-content-status')!;
    //   element.style.display = 'flex'
    // },
    // closeModalChooseStatus() {
    //   const element = document.getElementById('modal-select-element-with-content-status')!;
    //   element.style.display = 'none'
    // },
    setCheckedRequest(item: Request) {
      if (item.requestId) {
        let index = this.listCheckedRequests.findIndex((checkedRequest: Request) => {
          return checkedRequest.id === item.id
        })

        if (index !== -1) {
          this.listCheckedRequests = this.listCheckedRequests.filter((request: Request) => {
            return item.requestId !== request.requestId
          })
        } else {
          this.requests.map((request: Request) => {
            if (request.requestId === item.requestId) {
              this.listCheckedRequests.push(request)
            }
          })
        }
      } else {
        let index = this.listCheckedRequests.findIndex((checkedRequest: Request) => {
          return checkedRequest.id === item.id
        })

        if (index !== -1) {
          this.listCheckedRequests.splice(index, 1);
        } else {
          this.listCheckedRequests.push(item);
        }
      }
    },
    setDefault() {
      this.$store.commit('setUploadFileErrors', [])
    },
    closeChooseApproveModal() {
      const element = document.getElementById('modal-select-element-with-content-with-approved')!;
      element.style.display = 'none'
    },
    showChooseApproveModal() {
      const element = document.getElementById('modal-select-element-with-content-with-approved')!;
      element.style.display = 'flex'
    },
    setDefaultSettingsDocument() {
      this.documentSettings = {
        isPending: true,
        withApproved: false,
      }

      this.activeFilters = null
      this.listCheckedRequests = []
    },
    // openModalChooseApproveDate() {
    //   // this.closeModalChooseStatus()
    //   const element = document.getElementById('modal-approve-choose-requests')!;
    //   element.style.display = 'flex'
    // },
    // closeModalChooseApproveDate() {
    //   const element = document.getElementById('modal-approve-choose-requests')!;
    //   element.style.display = 'none'
    // },
    async uploadFile(withApproved: boolean) {
      await this.$store.dispatch('generateDocumentRequest', {
        ids: this.listCheckedRequests.map((request: Request) => request.id),
        isPending: true,
        withApproved
      }).then(() => {
        this.closeChooseApproveModal()
        this.setDefaultSettingsDocument()
      })
    },
    // async approveUploadFile(requestData: any) {
    //   await this.$store.dispatch('changeRequestsStatusToApprove', {
    //     requestId: this.listCheckedRequests && this.listCheckedRequests[0].requestId,
    //     numberOfCertification: requestData.number,
    //     createdAt: new Date(requestData.dateRange.start).toISOString(),
    //     expiredAt: new Date(requestData.dateRange.end).toISOString(),
    //   }).then(() => {
    //     this.getRequests({ pageNumber: 0 })
    //     this.setDefaultSettingsDocument()
    //   })
    // },
    // async uploadFileAndChangeStatusRequests(isPending: boolean) {
    //   this.documentSettings.isPending = isPending

      // if (this.nextRequestsStatus === CertificateRequestsStatus.SUCCESS && isPending) {
      //   this.openModalChooseApproveDate()
      // } else if (this.nextRequestsStatus === CertificateRequestsStatus.SUCCESS && !isPending) {
      //   await this.generateDocumentRequest()
      // }
      //
      // if (this.nextRequestsStatus === CertificateRequestsStatus.PENDING) {
      //   await this.generateDocumentRequest()
      // }
    // },
    async generateDocumentRequest() {
      await this.$store.dispatch('generateDocumentRequest', {
        ids: this.listCheckedRequests.map((request: Request) => request.id),
        isPending: true,
        withApproved: false
      }).then(() => {
        this.getRequests({ pageNumber: 0 })
        this.setDefaultSettingsDocument()
        // this.closeModalChooseStatus()
      })
    },
    openModalAddingRequest() {
      const element = document.getElementById('modal-add-request')!;
      element.style.display = 'flex'
    },
    closeModalAddingRequest() {
      this.setDefault()
      const element = document.getElementById('modal-add-request')!;
      element.style.display = 'none'
    },
    async deleteCertificate(item: Certificate) {
      await this.$store.dispatch('deleteCertificate', item.id)
    },
    async getRequests(nextPageable: { pageNumber: number }) {
      const { pageable: { pageNumber }, totalPages } = await this.$store.dispatch('fetchRequests', {
        ...nextPageable,
        pageSize: this.pageable.pageSize,
        filters: {
          ...this.pageable.filters
        }
      })

      this.pageable = {
        ...this.pageable,
        pageNumber,
        totalPages
      }

      this.setDefaultSettingsDocument()
    }
  },
  async mounted() {
    await this.getRequests(this.pageable)
  }
})
